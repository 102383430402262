import React, {lazy, Suspense} from "react";
import {Spin} from "antd";

export const loginRoutes = [
    {
        path: "/",
        element: lazy(() => import("./pages/Ones")),
    },
];

// 路由处理方式
const changeRouter = (routers: any) => {
    return routers.map((item: any) => {
        if (item.children) {
            item.children = changeRouter(item.children);
        }
        if (item.element && item.path !== "*")
            item.element = (
                <Suspense fallback={<Spin spinning={true}></Spin>}>
                    <item.element/>
                </Suspense>
            );
        return item;
    });
};

// 必须这样子，不然会报什么hook错误的问题
export const loginRouters = changeRouter(loginRoutes);